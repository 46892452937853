import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import HeaderBanner from "../components/index/header_jumbotron"
import CategoryTitle from "../components/index/categorytitle"


const Cozumler = () => (
	<Layout>
		<SEO title="Çözümler" />
		<div id="cozumler" className="inlinePage center-h footerFixed">
			<div className="container-fluid w80 col-xs-w95">
				<CategoryTitle title="SERENAY" subTitle="Çözümlerimiz" description="Çözümlerimiz" isDark={false} position="inline" />
				<div className="row">
						<div className="flex w100 left h100 col-xs-block customHeight">
							<HeaderBanner title="Özel Yazılım Çözümleri" description="" icon="icon-code1" linkTo="/ozel-yazilim" />
							<HeaderBanner title="Özel Sunucu Hizmetleri" description="" icon="icon-web-hosting" linkTo="/ozel-sunucu-hizmetleri" />
							<HeaderBanner title="Kullandığımız Teknolojiler" description="" classAdd="col-xs-w100" icon="icon-heart2" linkTo="/teknolojiler" />
						</div>
				</div>
			</div>
		</div>
	</Layout>
)

export default Cozumler
